.wContainer{
    width: 100%;
    background-color: #444be9;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wContainer > h2{
    text-shadow: 2px 1px 6px rgba(0,0,0,0.6);
}

.wContainer > ul > li{
    text-shadow: 2px 1px 6px rgba(0,0,0,0.6);
}

.wContainer .cwContainer > ul > li > p{
    text-shadow: 2px 1px 6px rgba(0,0,0,0.6);
    font-size: 20px;
}