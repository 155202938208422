.footerContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: black;
    width: 100vw;
    max-width: 100%;
    flex-wrap: wrap;
    padding: 30px;
}

.leftFooter > *{
    color: white;
    width: 100%;
}

.leftFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.leftFooter > .iconsAddress {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
}

.iconsAddress > p{
    margin-left: 5px;
    font-size: 20px;
    font-weight: 400;
}
.iconsAddress > i{
    margin: 5px 5px 0 0;
}

.rightFooter > iframe{
    margin-top: 10px;
    border-radius: 7px;
}

.copyRight{
    width: 100vw;
    max-width: 100%;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    letter-spacing: 2px;
    font-size: 20px;
    text-align: center;
}

@media screen and (max-width : 450px) {
    .copyRight{
        font-size: 16px;
    }
}