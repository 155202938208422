.clContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #444be9;
    padding: 5px;
}

.carousel{
    position: relative;
    width: 30%;
    height: 100px;
    padding: 20px;
    margin-bottom: 10px;
}

.carousel > .left, .right{
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    top: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.carousel > .left{
    left: -30px;
}

.carousel > .right{
    right: -30px;
}
@media screen and (max-width : 728px) {
    .carousel{
        width: 80%;
        height: max-content;
    }
}