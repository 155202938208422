
.empty{
    display: none;
}
.wContainer > .empty >.phoneBlocks{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.wContainer > .logoWork{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.wContainer > .logoWork > i{
    color: white;
    font-size: 50px;
    margin: 0 10px;
    cursor: pointer;
}


.wContainer > .logoWork > .fa-youtube:hover{
    color: red;
}

.wContainer > .logoWork > .fa-facebook:hover{
    color: blue;
}

.wContainer > .logoWork > .fa-instagram:hover{
    color: purple;
}
@media screen and (max-width : 500px) {
    .myImage{
        display: none;
    }
    .empty{
        display: block;
        margin: 5px 0;
    }
}