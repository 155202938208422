.heroContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #444be9;
    flex-wrap: wrap;
    padding: 10px 0;
    position: relative;
    height: max-content;
    overflow: hidden;
}

.rightHero{
    width: max-content;
    position: relative;
    z-index: 100;
}
.rightHero > img{
    z-index: 2;
}

.yellowFont{
    font-size: 50px;
    font-weight: bolder;
    letter-spacing: 4px;
    color: yellow;
    text-shadow: 2px 1px 6px rgba(0,0,0,0.6);
}

.buttonBlocks{
    margin-top: 30px;
}

.leftHero > .buttonBlocks > a{
    text-decoration: none;
    color: black;
    background-color: white;
    padding: 8px 10px;
    font-weight: bold;
    margin-right: 10px;
    border-radius: 7px;
}

.leftHero > .smallBlocks{
    width: 370px;
    height: max-content;
    text-align: justify;
    word-wrap: break-word;
    color: white;
    font-size: 16px;
}

@media screen and (max-width: 810px) {
    .rightHero > img{
        width: 300px;
        height: 300px;
    }
    .blob{
        opacity: 0;
    }
}

@media screen and (max-width: 659px) {
    .rightHero{
        display: none;
    }
    .leftHero{
        padding: 20px;
    }
}

@media screen and (max-width : 417px) {
    .yellowFont{
        font-size: 43px;
        padding: 2px 18px;
    }
    .leftHero > .smallBlocks{
        padding: 0 18px;
    }
    .leftHero{
        margin: 10px;
        max-height: max-content;
    }

    .buttonBlocks{
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .leftHero > .buttonBlocks > a{
        margin-right: 0px;
        margin: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }
}