.myNavbar{
    width: 100%;
    height: 70px;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.myNavbar .logoBox{
    font-size: 25px;
    margin-left: 15px;
}

.mobileIcons{
    font-size: 30px;
    cursor: pointer;
    display: none;
    margin-right: 20px;
}

.linksBox{
    padding: 0 20px;
    z-index: 10
}

.linksBox a{
    color: black;
    text-decoration: none;
    padding: 5px 15px;
    margin: 0 10px;
    background-color: white;
    border-radius: 20px;
    transition: all 0.5s linear;
}

@media screen and (max-width:445px) {
    .mobileIcons{
        display: block;
    }

    .linksBox{
        position: absolute;
        height: max-content;
        max-width: 100%;
        min-width: 100%;
        height: 100vh;
        top: 60px;
        background-color: #444be9;
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
        transition: all 0.3s linear;
    }
    .linksBox a{
        margin: 10px 0;
    }
    .upper{
        transform:translateX(-1000px)
    }
}