.contactContainer{
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    background-color: whitesmoke;
    flex-wrap: wrap;
}

.largeMe{
    font-size: 50px;
    font-weight: 600;
    text-shadow: 2px 1px 6px rgba(0,0,0,0.6);
    letter-spacing: 3px;
}

.contactContainer > .addressBlock{
    padding: 5px;
}
.contactContainer > .addressBlock > .iconsAddress {
    display: flex;
    margin: 5px 0;
}

.iconsAddress > p{
    margin-left: 5px;
    font-size: 20px;
    font-weight: 400;
}
.iconsAddress > i{
    margin: 5px 5px 0 0;
}

.formContainer > form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 30px;
    border-radius: 7px;
}

.formContainer > form > input{
    width: 280px;
    border: none;
    height: 40px;
    font-size: 16px;
    outline: none;
    border-bottom: 2px solid black;
    margin: 10px 0;
}

.formContainer > form > p{
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    margin: 5px 0;
}

.askingTag{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 8px;
}

.formContainer > form > button{
    width: 100%;
    margin: 5px 0;
    border-radius: 7px;
    outline: none;
    border: none;
    background-color: blue;
    color: white;
    height: 30px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

@media screen and (max-width : 417px) {
    .formContainer > form{
        margin-top: 20px;
    }
}