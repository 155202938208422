@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

img {  
  user-drag: none;  
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-track{
  background-color: white;
}

::-webkit-scrollbar-thumb{
  background:#444be9;
  height: 50px;
  border-radius: 20px;
}


.hiddenTop{
  transform: translateX(-1000px);
}

.moveToTop{
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  bottom: 0;
  right: 0;
  z-index: 998;
  margin: 20px;
  transform: translateX(0);
  transition: all 0.2s linear;
  animation: movingMe 2s linear infinite alternate-reverse;
}

@keyframes movingMe {
  0%{
    transform: translateY(0);
  }
  100%{
    transform: translateY(-10px);
  }
}